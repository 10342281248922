export function convertISO8601ToUTC(isoTimestamp) {
  const timezone = +8;
  // Parse ISO 8601 timestamp and convert it to UTC timestamp
  const timestampUTC = Date.parse(isoTimestamp) - timezone * 60 * 60 * 1000; // 8 hours ahead (in milliseconds)

  // Create a Date object for the UTC timestamp
  const utcDate = new Date(timestampUTC);

  // Get the UTC hour (in 24-hour format) as an integer
  const utcHour = utcDate.getUTCHours();

  return utcHour;
}
