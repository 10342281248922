import React from "react";
import { Line } from "react-chartjs-2";
import {
  LinearScale,
  CategoryScale,
  Chart,
  Title,
  PointElement,
  LineElement,
} from "chart.js";

function GenericLineChart({ name, units, timestamps, values }) {
  // Extract timestamps and wind speeds from the windData object
  const timestampConverted = timestamps
    .slice(0, 48)
    .map((data) =>
      new Date(data).toLocaleString("en-AU", { timeZone: "Etc/GMT+8" })
    ); // Convert Unix timestamps to JavaScript timestamps
  const dataToRender = values.slice(0, 48);

  const data = {
    labels: timestampConverted,
    datasets: [
      {
        label: `${name} (${units})`,
        data: dataToRender,
        fill: true,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: `${name} (${units})`,
        },
        beginAtZero: true,
      },
    },
  };

  Chart.register(LinearScale, CategoryScale, Title, PointElement, LineElement);

  return (
    <div>
      <h2>Hourly {name} Chart</h2>

      <Line data={data} options={options} />
    </div>
  );
}

export default GenericLineChart;
