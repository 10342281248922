import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { convertISO8601ToUTC } from "../utils/iso8601ToUTC";

function GenericAverageLineChart({ name, units, timestamps, values }) {
  const [hourlyData, setHourlyData] = useState([]);

  useEffect(() => {
    calculateHourly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamps, values]);

  const calculateHourly = () => {
    if (timestamps.length === 0 || values.length === 0) {
      setHourlyData([]);
      return;
    }

    const groupedData = {};
    timestamps.forEach((timestamp, index) => {
      // Convert Unix timestamp to JavaScript timestamp
      const hourFormat24 = convertISO8601ToUTC(timestamp);
      if (!groupedData[hourFormat24]) {
        groupedData[hourFormat24] = [];
      }
      groupedData[hourFormat24].push(values[index]);
    });

    const hourlyData = [];
    for (let hour = 0; hour < 24; hour++) {
      if (groupedData[hour]) {
        const total = groupedData[hour].reduce((sum, value) => sum + value, 0);
        const average = total / groupedData[hour].length;
        hourlyData.push(average.toFixed(2)); // Round to two decimal places
      } else {
        hourlyData.push(null);
      }
    }

    setHourlyData(hourlyData);
  };

  const data = {
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Hour labels
    datasets: [
      {
        label: `${name} (${units})`,
        data: hourlyData,
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Hour of the Day",
        },
      },
      y: {
        title: {
          display: true,
          text: `${name} (${units})`,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h2>Hourly {name} Chart</h2>
      <Line data={data} options={options} />
    </div>
  );
}

export default GenericAverageLineChart;
