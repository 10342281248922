import React, { useState, useEffect } from "react";
import axios from "axios";
import Map from "./components/Map";
import "./App.css";
import GenericAverageLineChart from "./components/GenericAverageLineChart";
import GenericLineChart from "./components/GenericLineChart";
import LocationButton from "./components/LocationButton";

function App() {
  const [meteoData, setWindData] = useState([]);
  const [location, setLocation] = useState({
    lat: -31.58102151250463,
    lon: 115.65082348084337,
  });

  useEffect(() => {
    fetchWindData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchWindData = async () => {
    try {
      const response = await axios.get(
        `https://api.open-meteo.com/v1/forecast?latitude=${location.lat}&longitude=${location.lon}&hourly=wind_speed_10m,precipitation,cloud_cover_low,direct_radiation`
      );
      setWindData(response.data.hourly);
    } catch (error) {
      console.error("Error fetching wind data:", error);
    }
  };

  const handleMapClick = (e) => {
    setLocation({ lat: e.latlng.lat, lon: e.latlng.lng });
  };

  return (
    <div className="App">
      <div className="left">
        <Map location={location} onMapClick={handleMapClick} />
        <LocationButton setLocation={setLocation} />
      </div>
      <div className="right">
        {meteoData?.wind_speed_10m?.length > 0 && (
          <GenericLineChart
            name="Windspeed"
            units="km/h"
            timestamps={meteoData.time}
            values={meteoData.wind_speed_10m}
          />
        )}
        {meteoData?.wind_speed_10m?.length > 0 && (
          <GenericAverageLineChart
            name="Windspeed average"
            units="km/h"
            timestamps={meteoData.time}
            values={meteoData.wind_speed_10m}
          />
        )}
        {meteoData?.direct_radiation?.length && (
          <GenericLineChart
            name="Solar radiation"
            units="W/m^2"
            timestamps={meteoData.time}
            values={meteoData.direct_radiation}
          />
        )}
        {meteoData?.direct_radiation?.length && (
          <GenericAverageLineChart
            name="Solar radiation average"
            units="W/m^2"
            timestamps={meteoData.time}
            values={meteoData.direct_radiation}
          />
        )}
        {meteoData?.cloud_cover_low?.length > 0 && (
          <GenericLineChart
            name="Cloud coverage"
            units="mm"
            timestamps={meteoData.time}
            values={meteoData.cloud_cover_low}
          />
        )}
        {meteoData?.precipitation?.length > 0 && (
          <GenericLineChart
            name="Rainfall"
            units="mm"
            timestamps={meteoData.time}
            values={meteoData.precipitation}
          />
        )}
      </div>
    </div>
  );
}

export default App;
