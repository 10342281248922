import React from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

function ClickHandler({ onMapClick }) {
  const map = useMapEvent("click", (e) => {
    onMapClick(e);
    map.flyTo(e.latlng, map.getZoom());
  });
  return null;
}

function FlyTo({ location }) {
  const map = useMap();
  if (location) {
    console.log(location);
    map.flyTo(location, 12);
  }
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

function Map({ location, onMapClick }) {
  return (
    <MapContainer
      center={[0, 0]}
      zoom={2}
      style={{ height: "400px", width: "100%" }}
    >
      <FlyTo location={location} />
      <ClickHandler onMapClick={onMapClick} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={location} />
    </MapContainer>
  );
}

export default Map;
