import React from "react";

// Define CSS styles for the button
const buttonStyles = {
  backgroundColor: "#007bff", // Blue color
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
};

function LocationButton({ setLocation }) {
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;

          // Call the setLocation hook with the obtained location
          setLocation({ lat: latitude, lon: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser.");
    }
  };

  return (
    <button style={buttonStyles} onClick={handleGetLocation}>
      Get My Location
    </button>
  );
}

export default LocationButton;
